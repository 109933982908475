import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/gallery/ministrylinq",
  "project": "MinistryLINQ",
  "projectID": "ministrylinq",
  "projectURL": "https://www.ministrylinq.com",
  "projectDate": "2020-03-21"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Gallery = makeShortcode("Gallery");
const GalleryImg = makeShortcode("GalleryImg");
const GalleryVid = makeShortcode("GalleryVid");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas, nesciunt illum quos id quo repellat dolorum totam sed voluptatem, numquam harum accusamus delectus, soluta laborum? Minima libero atque rerum deserunt? */
    }
    <Gallery mdxType="Gallery">
  <GalleryImg src="ministrylinq_home.png" alt="Home page screenshot" title="Home" mdxType="GalleryImg" />
  <GalleryVid file="ministrylinq_testimonials-animation" mdxType="GalleryVid" />
  <GalleryImg src="ministrylinq_faq.png" alt="FAQ page screenshot" title="FAQ" mdxType="GalleryImg" />
  <GalleryImg src="ministrylinq_pricing.png" alt="Pricing page screenshot" title="Pricing" mdxType="GalleryImg" />
  <GalleryImg src="ministrylinq_get-started.png" alt="Get Started page screenshot" title="Get Started" mdxType="GalleryImg" />
  <GalleryImg src="ministrylinq_blog.png" alt="Blog page screenshot" title="Blog" mdxType="GalleryImg" />
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      